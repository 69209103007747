<template>
  <section
    id="strypeFAQTOCSection"
    class="vflex"
  >
    <h3>Strype Frequently Asked Questions</h3>
    <span class="FAQ-TOC">Content</span>
    <div
      v-for="cat in faqCats"
      :key="cat.id+'TOC'"
    >
      <span class="FAQ-CAT"><a :href="'#'+cat.id">{{ cat.title }}</a></span>
      <div
        v-for="catQt in cat.qts"
        :key="catQt.id+'TOC'"
      >
        <span
          class="qt-title"
        >
          <a :href="'#'+catQt.id">{{ catQt.title }}</a>
        </span>
      </div>
    </div>
  </section>
  <hr class="FAQ-hr">
  <section id="strypeFAQQtSection">
    <div
      v-for="cat in faqCats"
      :key="cat.id"
    >
      <span
        :id="cat.id"
        class="FAQ-CAT"
      >{{ cat.title }}</span>
      <div
        v-for="catQt in cat.qts"
        :key="catQt.id"
      >
        <span
          :id="catQt.id"
          class="qt-title"
        >{{ catQt.title }}</span>
        <p
          v-for="(qtAnswerPart, index) in catQt.answer"
          :key="catQt.id+'A'+index"
          v-html="qtAnswerPart"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { FAQCatEntry } from "@/helpers/types"
import { defineComponent } from "vue"

export default defineComponent({
    name: "StrypeFAQView",

    computed: {
        faqCats (): FAQCatEntry[] {
            // Rather than getting the content directly as HTML entries, we store them here as JS objects and get a simple rendereding in the HTML template
            // Note: the string content can include HTML tags if bold, italic etc is needed within.
            return [
                {
                    id: "StrypeFAQGeneralQt",
                    title: "General Questions",
                    qts: [{
                        id: "StrypeFAQPrivacy",
                        title: "Do you have a privacy policy?",
                        answer: ["Strype does not collect any private data. No user data is stored on our servers.",
                            "<a href='/policy' target='_blank'>Here</a> is our privacy policy.",
                        ],
                    },
                    {
                        id: "StrypeFAQReq",
                        title: "What are the requirements for using Strype?",
                        answer: ["Strype is an online editor, which only requires a recent Internet browser and an Internet connection.",
                            "Our development team tests Strype with Chrome, Safari and Firefox.",
                        ],
                    },
                    {
                        id: "StrypeFAQPythonVers",
                        title: "What version of Python is supported by Strype?",
                        answer: ["Strype supports a version close to Python 3.7.", "Since Strype runs in the browser, Strype can only support a (large) subset of Python."],
                    },
                    {
                        id: "StrypeFAQSaveProj",
                        title: "Where does Strype save my project?",
                        answer: ["The project is saved in the local browser storage but you can save it as a file on your machine, or save it to Google Drive.",
                            "(This is particularly useful if you are using a public computer or if the browser's cache is systematically cleared.)"],
                    },
                    {
                        id: "StrypeFAQCost",
                        title: "What does Strype cost?",
                        answer: ["Strype is free to use and <a href=\"https://github.com/k-pet-group/Strype\" target=\"_blank\">open source</a>."],
                    },
                    {
                        id: "StrypeFAQLocalisation",
                        title: "Is Strype available in different languages?",
                        answer: ["Yes. Strype is available in a few different languages. Open the Strype menu (3 bars icon at the top-left of the screen), and look for the Preferences menu section.",
                            "(The Strype development team relies on volunteering translators. If you notice an error in an existing translation, or want to provide translations for a language not yet supported by Strype, please see <a href=\"https://github.com/k-pet-group/Strype/tree/main/docs/translation\" target=\"_blank\">our translation documentation</a>.)"],
                    },
                    {
                        id: "StrypeFAQGDriveValid",
                        title: "\"Google hasn’t verified this app\" shows when I try to access Google Drive in Strype. Why? What should I do?",
                        answer: ["This message is generated by Google for unverified applications using Google Drive.", "Strype is currently being assessed to receive certification, and be listed as a verified application by Google.",
                            "In the meantime, you can still use Google Drive with Strype: in the security popup, click on \"advanced\", then on \"Go to strype.org (unsafe)\" which is near the end of the popup."],
                    }],
                },
                {
                    id: "StrypeFAQMBQt",
                    title: "Strype for micro:bit",
                    qts: [{
                        id: "StrypeFAQMBConnect",
                        title: "How do I execute my Strype code on the micro:bit",
                        answer: ["This depends on your browser. In all cases, first make sure the micro:bit is connected via USB to your computer, and then click on the button \"Run on micro:bit\" at the bottom-right of the screen.",
                            "If the browser supports direct connection with the micro:bit, you will be asked to select your micro:bit the first time you send your code to the board, and the code will be sent to the micro:bit and run.",
                            "Otherwise, follow the directions showing on your screen to save your code on the micro:bit (you can download the Hex file from the Strype menu, click on the 3 bars icon at the top-left of the screen, and clicking on \"Convert to Hex file\")."],
                    }],
                },
            ]
        },
    },
})
</script>

  <style>
  #strypeFAQTOCSection h3 {
      text-align: center;
  }

  .FAQ-TOC {
      font-weight: 800;
      font-size: large;
  }

  .FAQ-CAT, .FAQ-CAT a, .qt-title, .qt-title > a {
      color: rgb(27,69,111);
  }

  .FAQ-CAT {
      font-weight: 600;
  }

  section#strypeFAQTOCSection > div:last-child {
      margin-top: 10px;
  }

  .qt-title {
      margin-left: 5%;
      padding-top: 0px;
  }

  .FAQ-hr {
      margin: 1rem 10%;
  }

  #strypeFAQQtSection {
      padding-top: 0px;
  }

  #strypeFAQQtSection p {
      margin-left: 7%;
      margin-bottom: 0px;
  }
  #strypeFAQQtSection p:last-child {
      margin-left: 7%;
      margin-bottom: 10px;
  }
  </style>
