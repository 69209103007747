<template>
  <section>
    <div>
      <h1>This page does not exist.</h1>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "PageNotFoundView",
})
</script>

<style>
</style>
