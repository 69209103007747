<template>
  <section
    id="strypeHistoryViewSection"
    class="vflex"
  >
    <h2>Release history</h2>
    <div>
      <div
        v-for="(release, index) in history"
        :key="'strypeRelease_'+index"
        class="strype-history-entry-container"
      >
        <b>{{ release.date }}</b>
        <div class="strype-history-entry-sub-container">
          <span v-html="release.preambleDetails" />
          <span v-if="release.generalDetails">
            General Details
          </span>
          <ul type="circle">
            <li
              v-for="(generalDetail,index2) in release.generalDetails"
              :key="'strypeRelease_'+index+'general_'+index2"
              v-html="generalDetail"
            />
          </ul>
          <span v-if="release.standardEditorDetails">
            Strype standard editor
          </span>
          <ul type="circle">
            <li
              v-for="(standardEditorDetail,index2) in release.standardEditorDetails"
              :key="'strypeRelease_'+index+'standardeditor_'+index2"
              v-html="standardEditorDetail"
            />
          </ul>
          <span v-if="release.microbitEditorDetails">
            Strype for micro:bit
          </span>
          <ul type="circle">
            <li
              v-for="(microbitEditorDetail,index2) in release.microbitEditorDetails"
              :key="'strypeRelease_'+index+'general_'+index2"
              v-html="microbitEditorDetail"
            />
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { StrypeHistoryEntry } from "@/helpers/types"
import { defineComponent } from "vue"

export default defineComponent({
    name: "StrypeHistoryView",

    computed: {
        history (): StrypeHistoryEntry[] {
            // Rather than getting the content directly as HTML entries, we store them here as JS objects and get a simple rendereding in the HTML template
            // note: the string content can include HTML tags if bold, italic etc is needed within.
            return [
                {
                    date: "Version 1.0.2 (released 24 January 2025)",
                    generalDetails: [
                        "Autocompletion technical improvements (beta)",
                        "Disabled frame handling change",
                        "Few UI improvements",
                    ],
                },
                {
                    date: "Version 1.0.1 (released 13 December 2024)",
                    generalDetails: [
                        "Drag and drop of frames: improved usability",
                        "Added a functionality to download frames as image",
                        "Added feature to reconnect to Google Drive when a project loaded from Google Drive has been reloaded in the browser",
                        "Added feature to support \"select-all\" on frames",
                        "Various bug fixes and interface revamps",
                    ],
                    standardEditorDetails: ["Added Spanish, German and Chinese locales"],
                },
                {
                    date: "Version 1.0 (released 16  September 2024)",
                    standardEditorDetails: [],
                    microbitEditorDetails: [],
                },
            ]
        },
    },
})
</script>

<style>
#strypeHistoryViewSection h2 {
    text-align: center;
}

.strype-history-entry-container {
    padding-top: 20px;
}

.strype-history-entry-container:nth-child(odd) {
    background-color: rgb(244, 244, 248);
}

.strype-history-entry-sub-container {
    padding-left:20px;
}
</style>
